<script setup lang="ts">
import { useTheme } from 'vuetify'
import type { ApexOptions } from 'apexcharts'

// import AnalyticsEarningReportsWeeklyOverview from '@/views/admin/dashboard/AnalyticsEarningReportsWeeklyOverview.vue'
// import AnalyticsMonthlyCampaignState from '@/views/admin/dashboard/AnalyticsMonthlyCampaignState.vue'
// import AnalyticsProjectTable from '@/views/admin/dashboard/AnalyticsProjectTable.vue'
// import AnalyticsSalesByCountries from '@/views/admin/dashboard/AnalyticsSalesByCountries.vue'
// import AnalyticsSalesOverview from '@/views/admin/dashboard/AnalyticsSalesOverview.vue'
// import AnalyticsSourceVisits from '@/views/admin/dashboard/AnalyticsSourceVisits.vue'
// import AnalyticsSupportTracker from '@/views/admin/dashboard/AnalyticsSupportTracker.vue'
// import AnalyticsTotalEarning from '@/views/admin/dashboard/AnalyticsTotalEarning.vue'

// import CardStatisticsVertical from '@core/components/CardStatisticsVertical.vue'
definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

// const { admin } = useAuthStore()
const vuetifyTheme = useTheme()
const currentTheme = vuetifyTheme.current.value.colors

const chartOptions: ApexOptions = {
  plotOptions: {
    bar: {
      columnWidth: '20%',
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    foreColor: 'white',
    type: 'bar',
    height: 250,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  colors: [currentTheme.success],
  tooltip: {
    x: {
      format: 'dd MMM yyyy',
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
  annotations: {
    yaxis: [{
      borderColor: '#999',
    }],
    xaxis: [{
      borderColor: '#999',
    }],
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: 'smooth',
  },
  xaxis: {
    type: 'date',
    tickAmount: 6,
    stepSize: 1,

  },
  yaxis: {
    stroke: {
      width: 0,
    },
    show: true,
  },
}

const statisticsVertical = reactive({
  title: 'Monthly Revenue',
  color: 'success',
  stats: '',
  height: 250,
  series: [
    {
      name: 'Users',
      data: [],
    },
  ],
  chartOptions,
})

const getStats = async () => {
  const res = await $api('/api/admin/home')

  statisticsVertical.stats = res.total_users
  statisticsVertical.series[0].data = res.users_per_day.data
}

onMounted(() => {
  getStats()
})
</script>

<template>
  <VRow>
    <!-- 👉 Website analytics -->

    <!-- 👉 Sales Overview -->
    <!--
      <VCol
      cols="12"
      md="3"
      sm="6"
      >
      <AnalyticsSalesOverview />
      </VCol>
    -->

    <!-- 👉 Statistics Vertical -->
    <VCol
      cols="12"
      md="4"
      sm="6"
    >
      <VCard>
        <VCardTitle>
          <h5 class="text-h5 mb-2">
            Welcome back,<span class="text-h4"> Felecia 👋🏻 </span>
          </h5>
        </VCardTitle>
        <VCardSubtitle>
          Admin Dashboard
        </VCardSubtitle>
        <VCardText class="d-flex justify-space-between">
          <div>
            <div class="d-flex align-center">
              <VAvatar
                color="primary"
                size="54"
                variant="tonal"
                rounded="sm"
                class="me-4"
              >
                <VIcon
                  icon="tabler-user"
                  size="38"
                />
              </VAvatar>
              <div>
                <h6 class="text-h6 text-medium-emphasis">
                  Users
                </h6>
                <h4 class="text-h4 text-primary">
                  34
                </h4>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex align-center">
              <VAvatar
                color="info"
                size="54"
                variant="tonal"
                rounded="sm"
                class="me-4"
              >
                <VIcon
                  icon="tabler-currency-dollar"
                  size="38"
                />
              </VAvatar>
              <div>
                <h6 class="text-h6 text-medium-emphasis">
                  Total Revenue
                </h6>
                <h4 class="text-h4 text-info">
                  3,500
                </h4>
              </div>
            </div>
          </div>
        </VCardText>
      </VCard>

      <VCard
        title="Pending"
        class="mt-4"
      >
        <VList>
          <VListItem title="Account Creation">
            <template #append>
              <span class="pe-2">0</span>
            </template>
          </VListItem>
          <VDivider />
          <VListItem title="New Bot Invoices">
            <template #append>
              <span class="pe-2">0</span>
            </template>
          </VListItem>
          <VDivider />
          <VListItem title="Extension Invoices">
            <template #append>
              <span class="pe-2">0</span>
            </template>
          </VListItem>
          <VDivider />
          <VListItem title="Add Fund Invoices">
            <template #append>
              <span class="pe-2">0</span>
            </template>
          </VListItem>
          <VDivider />
          <VListItem title="Affiliate Withdraw Requests">
            <template #append>
              <span class="pe-2">0</span>
            </template>
          </VListItem>
        </VList>
      </VCard>
    </VCol>

    <VCol
      md="8"
      sm="12"
    >
      <VRow>
        <VCol cols="4">
          <CardStatisticsHorizontal
            title="Subscriptions"
            color="primary"
            icon="tabler-copy-check"
            stats="25"
          />
        </VCol>
        <VCol cols="4">
          <CardStatisticsHorizontal
            title="This Month Revenue"
            color="primary"
            icon="tabler-archive"
            stats="25k"
          />
        </VCol>
        <VCol cols="4">
          <CardStatisticsHorizontal
            title="Active Bots"
            color="primary"
            icon="tabler-tag"
            stats="25k"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <CardStatisticsVertical v-bind="statisticsVertical" />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/libs/apex-chart.scss";
</style>
