<script setup lang="ts">
import OtpDialog from '@/views/admin/otp/OtpDialog.vue'
import WebhookDialog from '@/views/admin/webhook/WebhookDialog.vue'
import type { Pair } from '@api/models/pair'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

type Pairs = Record<string, Pair[]>

const users = ref([])
const exchanges = ref([])
const pairs = ref<Pairs>({})
const otpDialogOpen = ref(false)
const webhookDialogOpen = ref(false)
const command = ref('')
interface Command {
  title: string
  value: string
  hasRule?: boolean
}

interface State {
  rule: string
  command: Command
  seconds: number
  mode: string
  user: string
  exchange: string
  pair?: string
}

const initState = {
  rule: 'ALL',
  command: {
    title: 'Wait',
    value: 'WAIT',
  },
  seconds: 10,
  mode: 'DCA',
  user: '',
  exchange: '',
}

const value = ref<State[]>([{ ...initState }])

const getUsers = async () => {
  users.value = await $api('/api/admin/users?nopaginate')
}

const getExchanges = async () => {
  exchanges.value = await $api('/api/admin/exchanges?nopaginate')
}

const getPairs = async () => {
  pairs.value = await $api('/api/admin/pairs')
}

const modes = [
  {
    title: 'DCA',
    value: 'DCA',
  },
  {
    title: 'FULL',
    value: 'FULL',
  },
]

const commands = [
  {
    title: 'Wait',
    value: 'WAIT',
  },
  {
    title: 'Black List',
    value: 'BLACKLIST',
    hasRule: true,
  },
  {
    title: 'Close Deal',
    value: 'CLOSE DEAL',
    hasRule: true,
  },
  {
    title: 'Enable Bot',
    value: 'ENABLE BOT',
    hasRule: true,
  },
  {
    title: 'Disable Bot',
    value: 'DISABLE BOT',
    hasRule: true,
  },
  {
    title: 'Panic Sell',
    value: 'PANIC SELL',
    hasRule: true,
  },
  {
    title: 'Switch Mode',
    value: 'SWITCH TO',
  },
  {
    title: 'Sync Bot',
    value: 'SYNC BOT',
    hasRule: true,
  },
]

const rules = [
  {
    title: 'All',
    value: 'ALL',
  },
  {
    title: 'User',
    value: 'user',
  },
  {
    title: 'Exchange',
    value: 'exchange',
  },
]

const addCommand = () => {
  value.value.push({ ...initState })
}

const removeCommand = (index: number) => {
  value.value.splice(index, 1)
}

const submit = async () => {
  otpDialogOpen.value = true
}

const onOtpSuccess = async () => {
  const res = await $api('/api/admin/webhook', {
    method: 'POST',
    body: {
      commands: value.value,
    },
  })

  otpDialogOpen.value = false

  command.value = res.data

  webhookDialogOpen.value = true
}

onMounted(() => {
  getUsers()
  getExchanges()
  getPairs()
})
</script>

<template>
  <section>
    <VCard
      title="Create Webhook Command"
      class="mb-4"
    >
      <VCardText>
        <div
          v-for="(item, index) in value"
          :key="index"
          class="d-flex gap-2 align-end mt-4"
        >
          <AppSelect
            v-model="value[index].command"
            :items="commands"
            label="Command"
            return-object
          />
          <AppTextField
            v-if="item.command.value === 'WAIT'"
            v-model="item.seconds"
            label="Seconds"
          />
          <AppSelect
            v-if="item.command.value === 'SWITCH TO'"
            v-model="item.mode"
            :items="modes"
            label="Mode"
          />
          <AppSelect
            v-if="item.command.hasRule"
            v-model="item.rule"
            :items="item.command.value === 'BLACKLIST' ? rules.filter((rule) => rule.value !== 'user') : rules"
            label="Rule"
          />
          <AppSelect
            v-if="item.rule === 'user'"
            v-model="item.user"
            :items="users"
            label="User"
            item-value="username"
            item-title="name"
          />
          <AppSelect
            v-if="item.rule === 'exchange'"
            v-model="item.exchange"
            :items="exchanges"
            label="Exchange"
            item-value="code"
            item-title="name"
          />
          <AppAutocomplete
            v-if="['BLACKLIST', 'CLOSE DEAL'].includes(item.command.value)"
            v-model="item.pair"
            :items="pairs[item.rule === 'ALL' ? 'pairs' : (exchanges.find((exchange) => exchange.code === item.exchange)?.id ?? 'pairs')]"
            label="Pair"
            item-value="name"
            item-title="name"
          />
          <VBtn
            v-if="index === value.length - 1"
            @click="addCommand"
          >
            <VIcon icon="tabler-plus" />
          </VBtn>
          <VBtn
            v-else
            @click="removeCommand(index)"
          >
            <VIcon icon="tabler-minus" />
          </VBtn>
        </div>
        <VBtn
          block
          class="mt-8"
          @click="submit"
        >
          Submit
        </VBtn>
      </VCardText>
    </VCard>
  </section>
  <OtpDialog
    v-model:open="otpDialogOpen"
    @success="onOtpSuccess"
  />
  <WebhookDialog
    v-model:open="webhookDialogOpen"
    :command="command"
  />
</template>
