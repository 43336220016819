import _definePage_default_0 from '/var/www/html/resources/ts/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/var/www/html/resources/ts/pages/[...error].vue?definePage&vue'
import _definePage_default_3 from '/var/www/html/resources/ts/pages/admin/index.vue?definePage&vue'
import _definePage_default_4 from '/var/www/html/resources/ts/pages/admin/account/[tab].vue?definePage&vue'
import _definePage_default_5 from '/var/www/html/resources/ts/pages/admin/admins/index.vue?definePage&vue'
import _definePage_default_6 from '/var/www/html/resources/ts/pages/admin/admins/[id]/logs.vue?definePage&vue'
import _definePage_default_7 from '/var/www/html/resources/ts/pages/admin/affiliate/discounts.vue?definePage&vue'
import _definePage_default_8 from '/var/www/html/resources/ts/pages/admin/affiliate/history.vue?definePage&vue'
import _definePage_default_9 from '/var/www/html/resources/ts/pages/admin/affiliate/payments.vue?definePage&vue'
import _definePage_default_10 from '/var/www/html/resources/ts/pages/admin/blacklist.vue?definePage&vue'
import _definePage_default_11 from '/var/www/html/resources/ts/pages/admin/bots.vue?definePage&vue'
import _definePage_default_12 from '/var/www/html/resources/ts/pages/admin/check_2fa.vue?definePage&vue'
import _definePage_default_13 from '/var/www/html/resources/ts/pages/admin/demo/affiliates.vue?definePage&vue'
import _definePage_default_14 from '/var/www/html/resources/ts/pages/admin/demo/general.vue?definePage&vue'
import _definePage_default_15 from '/var/www/html/resources/ts/pages/admin/exchanges/index.vue?definePage&vue'
import _definePage_default_16 from '/var/www/html/resources/ts/pages/admin/faq/categories.vue?definePage&vue'
import _definePage_default_17 from '/var/www/html/resources/ts/pages/admin/faq/questions.vue?definePage&vue'
import _definePage_default_18 from '/var/www/html/resources/ts/pages/admin/invoices.vue?definePage&vue'
import _definePage_default_19 from '/var/www/html/resources/ts/pages/admin/notification.vue?definePage&vue'
import _definePage_default_20 from '/var/www/html/resources/ts/pages/admin/packages.vue?definePage&vue'
import _definePage_default_21 from '/var/www/html/resources/ts/pages/admin/security.vue?definePage&vue'
import _definePage_default_22 from '/var/www/html/resources/ts/pages/admin/settings.vue?definePage&vue'
import _definePage_default_23 from '/var/www/html/resources/ts/pages/admin/users/index.vue?definePage&vue'
import _definePage_default_24 from '/var/www/html/resources/ts/pages/admin/webhook.vue?definePage&vue'
import _definePage_default_25 from '/var/www/html/resources/ts/pages/check_2fa.vue?definePage&vue'
import _definePage_default_26 from '/var/www/html/resources/ts/pages/email/verify-[id]-[hash].vue?definePage&vue'
import _definePage_default_27 from '/var/www/html/resources/ts/pages/email/verify.vue?definePage&vue'
import _definePage_default_28 from '/var/www/html/resources/ts/pages/forgot-password.vue?definePage&vue'
import _definePage_default_29 from '/var/www/html/resources/ts/pages/login.vue?definePage&vue'
import _definePage_default_30 from '/var/www/html/resources/ts/pages/performance.vue?definePage&vue'
import _definePage_default_31 from '/var/www/html/resources/ts/pages/register.vue?definePage&vue'
import _definePage_default_32 from '/var/www/html/resources/ts/pages/reset-password.vue?definePage&vue'
import _definePage_default_33 from '/var/www/html/resources/ts/pages/user/index.vue?definePage&vue'
import _definePage_default_34 from '/var/www/html/resources/ts/pages/user/account/_file.vue?definePage&vue'
import _definePage_default_35 from '/var/www/html/resources/ts/pages/user/packages.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/var/www/html/resources/ts/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/var/www/html/resources/ts/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/admin',
    /* internal name: 'admin' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'admin',
        component: () => import('/var/www/html/resources/ts/pages/admin/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
      {
        path: 'account',
        /* internal name: 'admin-account' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'admin-account-tab',
            component: () => import('/var/www/html/resources/ts/pages/admin/account/[tab].vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
      {
        path: 'admins',
        /* internal name: 'admin-admins' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-admins',
            component: () => import('/var/www/html/resources/ts/pages/admin/admins/index.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
          {
            path: ':id',
            /* internal name: 'admin-admins-id' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'logs',
                name: 'admin-admins-id-logs',
                component: () => import('/var/www/html/resources/ts/pages/admin/admins/[id]/logs.vue'),
                /* no children */
              },
  _definePage_default_6
  )
            ],
          }
        ],
      },
      {
        path: 'affiliate',
        /* internal name: 'admin-affiliate' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'discounts',
            name: 'admin-affiliate-discounts',
            component: () => import('/var/www/html/resources/ts/pages/admin/affiliate/discounts.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'history',
            name: 'admin-affiliate-history',
            component: () => import('/var/www/html/resources/ts/pages/admin/affiliate/history.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'payments',
            name: 'admin-affiliate-payments',
            component: () => import('/var/www/html/resources/ts/pages/admin/affiliate/payments.vue'),
            /* no children */
          },
  _definePage_default_9
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'blacklist',
        name: 'admin-blacklist',
        component: () => import('/var/www/html/resources/ts/pages/admin/blacklist.vue'),
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'bots',
        name: 'admin-bots',
        component: () => import('/var/www/html/resources/ts/pages/admin/bots.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'check_2fa',
        name: 'admin-check2fa',
        component: () => import('/var/www/html/resources/ts/pages/admin/check_2fa.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
      {
        path: 'demo',
        /* internal name: 'admin-demo' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'affiliates',
            name: 'admin-demo-affiliates',
            component: () => import('/var/www/html/resources/ts/pages/admin/demo/affiliates.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
          {
            path: 'general',
            name: 'admin-demo-general',
            component: () => import('/var/www/html/resources/ts/pages/admin/demo/general.vue'),
            /* no children */
          },
  _definePage_default_14
  )
        ],
      },
      {
        path: 'exchanges',
        /* internal name: 'admin-exchanges' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-exchanges',
            component: () => import('/var/www/html/resources/ts/pages/admin/exchanges/index.vue'),
            /* no children */
          },
  _definePage_default_15
  )
        ],
      },
      {
        path: 'faq',
        /* internal name: 'admin-faq' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'categories',
            name: 'admin-faq-categories',
            component: () => import('/var/www/html/resources/ts/pages/admin/faq/categories.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
  _mergeRouteRecord(
          {
            path: 'questions',
            name: 'admin-faq-questions',
            component: () => import('/var/www/html/resources/ts/pages/admin/faq/questions.vue'),
            /* no children */
          },
  _definePage_default_17
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'invoices',
        name: 'admin-invoices',
        component: () => import('/var/www/html/resources/ts/pages/admin/invoices.vue'),
        /* no children */
      },
  _definePage_default_18
  ),
      {
        path: 'login',
        name: 'admin-login',
        component: () => import('/var/www/html/resources/ts/pages/admin/login.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      },
  _mergeRouteRecord(
      {
        path: 'notification',
        name: 'admin-notification',
        component: () => import('/var/www/html/resources/ts/pages/admin/notification.vue'),
        /* no children */
      },
  _definePage_default_19
  ),
  _mergeRouteRecord(
      {
        path: 'packages',
        name: 'admin-packages',
        component: () => import('/var/www/html/resources/ts/pages/admin/packages.vue'),
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: 'security',
        name: 'admin-security',
        component: () => import('/var/www/html/resources/ts/pages/admin/security.vue'),
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'settings',
        name: 'admin-settings',
        component: () => import('/var/www/html/resources/ts/pages/admin/settings.vue'),
        /* no children */
      },
  _definePage_default_22
  ),
      {
        path: 'users',
        /* internal name: 'admin-users' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'admin-users',
            component: () => import('/var/www/html/resources/ts/pages/admin/users/index.vue'),
            /* no children */
          },
  _definePage_default_23
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'webhook',
        name: 'admin-webhook',
        component: () => import('/var/www/html/resources/ts/pages/admin/webhook.vue'),
        /* no children */
      },
  _definePage_default_24
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/check_2fa',
    name: 'check2fa',
    component: () => import('/var/www/html/resources/ts/pages/check_2fa.vue'),
    /* no children */
  },
  _definePage_default_25
  ),
  {
    path: '/email',
    /* internal name: 'email' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '/email/verify/:id/:hash',
        name: 'email-verify-id-hash',
        component: () => import('/var/www/html/resources/ts/pages/email/verify-[id]-[hash].vue'),
        /* no children */
      },
  _definePage_default_26
  ),
  _mergeRouteRecord(
      {
        path: 'verify',
        name: 'email-verify',
        component: () => import('/var/www/html/resources/ts/pages/email/verify.vue'),
        /* no children */
      },
  _definePage_default_27
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/var/www/html/resources/ts/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_28
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/var/www/html/resources/ts/pages/login.vue'),
    /* no children */
  },
  _definePage_default_29
  ),
  _mergeRouteRecord(
  {
    path: '/performance',
    name: 'performance',
    component: () => import('/var/www/html/resources/ts/pages/performance.vue'),
    /* no children */
  },
  _definePage_default_30
  ),
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/var/www/html/resources/ts/pages/register.vue'),
    /* no children */
  },
  _definePage_default_31
  ),
  _mergeRouteRecord(
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('/var/www/html/resources/ts/pages/reset-password.vue'),
    /* no children */
  },
  _definePage_default_32
  ),
  {
    path: '/user',
    /* internal name: 'user' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'user',
        component: () => import('/var/www/html/resources/ts/pages/user/index.vue'),
        /* no children */
      },
  _definePage_default_33
  ),
      {
        path: 'account',
        /* internal name: 'user-account' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '_file',
            name: 'user-account-file',
            component: () => import('/var/www/html/resources/ts/pages/user/account/_file.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
          {
            path: 'affiliate',
            name: 'user-account-affiliate',
            component: () => import('/var/www/html/resources/ts/pages/user/account/affiliate.vue'),
            /* no children */
          },
          {
            path: 'profile',
            name: 'user-account-profile',
            component: () => import('/var/www/html/resources/ts/pages/user/account/profile.vue'),
            /* no children */
          },
          {
            path: 'security',
            name: 'user-account-security',
            component: () => import('/var/www/html/resources/ts/pages/user/account/security.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'faq',
        name: 'user-faq',
        component: () => import('/var/www/html/resources/ts/pages/user/faq.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'packages',
        name: 'user-packages',
        component: () => import('/var/www/html/resources/ts/pages/user/packages.vue'),
        /* no children */
      },
  _definePage_default_35
  )
    ],
  }
]
